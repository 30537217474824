import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Alert,
    Input,
    Button,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
} from 'reactstrap';
import * as api from '../store/actions/fetchActions';
import Wizard from '../components/wizard-steps/Wizard';
import Select from 'react-select';

import {useLocation} from 'react-router-dom';
import {Field, FormSpy} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {postRegistration} from '../store/actions/fetchActions';

const Error = ({name}) => (
    <Field
        name={name}
        subscription={{touched: true, error: true}}
        render={({meta: {touched, error}}) => (touched && error ? <span>{error}</span> : null)}
    />
);

const required = value => (value ? undefined : 'This field is required');

const Register = props => {
    const dispatch = useDispatch();
    const schools = useSelector(state => state.register.schools);
    const exams = useSelector(state => state.register.exams);
    const teachers = useSelector(state => state.register.teachers);
    const successfullySubmitted = useSelector(state => state.register.successfullySubmitted);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const key = urlParams.get('key');
    const submission_types = [
        {
            label: 'Paper and Pencil Administration',
            value: 'paper',
        },
        {
            label: 'Digital Administration',
            value: 'digital',
        },
    ];

    useEffect(() => {
        dispatch(api.getRegistrantOptionsByKey(key));
    }, []);

    const onSubmit = async values => {
        const data = values;
        // add key to data. This seems easier than adding and carrying the key to the data from the outset.
        data['key'] = key;
        const submitdata = data;
        submitdata['submission_type'] = data.submission_type.value;
        console.log('submit data - ');
        console.dir(submitdata);

        dispatch(postRegistration(submitdata));
    };

    return (
        <Row>
            <Col md={{size: 8, offset: 2}} sm={12}>
                <h4>Mock Exam Registration</h4>
                <hr />
                <br />
                {!successfullySubmitted && (
                    <Wizard initialValues={{teachers: [{}]}} onSubmit={onSubmit}>
                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (!values.school) {
                                    errors.school = 'Please select a school';
                                }
                                return errors;
                            }}
                        >
                            <div>
                                <h5>
                                    Please select ONE school to submit enrollment. If you are
                                    submitting orders for more than one school, you will need to
                                    submit additional forms.
                                </h5>
                                <Field
                                    name='school'
                                    options={schools.map((s, index) => ({
                                        value: s.school_id,
                                        label: s.school_name,
                                        attn: '',
                                        school_id: s.school_id,
                                        school_name: s.school_name,
                                        address1: s.address1,
                                        address2: s.address2,
                                        city: s.city,
                                        postal_code: s.postal_code,
                                        state: s.state,
                                    }))}
                                    render={({input, ...rest}) => (
                                        <Select
                                            {...input}
                                            {...rest}
                                            placeholder='Select a school...'
                                        />
                                    )}
                                />
                                <Error className='form-error' name='school' />
                            </div>
                        </Wizard.Page>
                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (!values.school.school_name) {
                                    errors.school_name = 'Required';
                                }
                                if (!values.school.address1) {
                                    errors.address1 = 'Please set an address';
                                }
                                if (!values.school.city) {
                                    errors.city = 'Please set a city';
                                }
                                if (!values.school.state) {
                                    errors.state = 'Please set a state';
                                }
                                if (!values.school.postal_code) {
                                    errors.postal_code = 'Please set a postal code';
                                }
                                return errors;
                            }}
                        >
                            <h5 style={{paddingBottom: '30px'}}>
                                Please confirm school address then click 'Next'.
                            </h5>
                            <FormGroup>
                                <Field
                                    name='school.school_name'
                                    // defaultValue={school ? school.name : ''}
                                    render={({input, ...rest}) => (
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>School Name</InputGroupText>
                                            </InputGroupAddon>
                                            <Input {...input} {...rest} disabled />
                                        </InputGroup>
                                    )}
                                />
                                <Error className='form-error' name='school_name' />
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <Field*/}
                            {/*        name='school.attn'*/}
                            {/*        render={({input, ...rest}) => (*/}
                            {/*            <InputGroup>*/}
                            {/*                <InputGroupAddon addonType='prepend'>*/}
                            {/*                    <InputGroupText>School Attn</InputGroupText>*/}
                            {/*                </InputGroupAddon>*/}
                            {/*                <Input {...input} {...rest} placeholder='School Attn...' />*/}
                            {/*            </InputGroup>*/}
                            {/*        )}*/}
                            {/*    />*/}
                            {/*    <Error className='form-error' name='attn' />*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <Field
                                    name='school.address1'
                                    // defaultValue={values.school ? values.school.address1 : ''}
                                    render={({input, ...rest}) => (
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>School Address 1</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                {...input}
                                                {...rest}
                                                placeholder='School Address...'
                                            />
                                        </InputGroup>
                                    )}
                                />
                                <Error className='form-error' name='address1' />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name='school.address2'
                                    render={({input, ...rest}) => (
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>School Address 2</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                {...input}
                                                {...rest}
                                                placeholder='School Address...'
                                            />
                                        </InputGroup>
                                    )}
                                />
                                <Error className='form-error' name='address2' />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name='school.city'
                                    render={({input, ...rest}) => (
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>School City</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                {...input}
                                                {...rest}
                                                placeholder='School City...'
                                            />
                                        </InputGroup>
                                    )}
                                />
                                <Error className='form-error' name='city' />
                            </FormGroup>
                            <div className='form-row'>
                                <FormGroup className='col-sm-6'>
                                    <Field
                                        name='school.state'
                                        render={({input, ...rest}) => (
                                            <InputGroup>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>School State</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    {...input}
                                                    {...rest}
                                                    placeholder='State...'
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <Error className='form-error' name='state' />
                                </FormGroup>
                                <FormGroup className='col-sm-6'>
                                    <Field
                                        name='school.postal_code'
                                        render={({input, ...rest}) => (
                                            <InputGroup>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        School Postal Code
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    {...input}
                                                    {...rest}
                                                    placeholder='Postal Code...'
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <Error className='form-error' name='postal_code' />
                                </FormGroup>
                            </div>
                        </Wizard.Page>
                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                values.teachers.forEach((teacher, index) => {
                                    if (!teacher.first_name) {
                                        const property_name = index + '_first_name';
                                        errors[property_name] = 'Required';
                                    }
                                    if (!teacher.last_name) {
                                        const property_name = index + '_last_name';
                                        errors[property_name] = 'Required';
                                    }
                                    if (!teacher.email) {
                                        const property_name = index + '_email';
                                        errors[property_name] = 'Required';
                                    }
                                    if (!teacher.exam) {
                                        const property_name = index + '_exam';
                                        errors[property_name] = 'Required';
                                    }
                                    if (!teacher.exam_count) {
                                        const property_name = index + '_exam_count';
                                        errors[property_name] = 'Required';
                                    }
                                });

                                return errors;
                            }}
                        >
                            <FieldArray name='teachers'>
                                {({fields}) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            const nameDisabled =
                                                fields.value[index].teacher_id !== 0;
                                            return (
                                                <div key={fields.value[index].key}>
                                                    <h5
                                                        style={{
                                                            paddingBottom: '30px',
                                                            paddingTop: '30px',
                                                        }}
                                                    >
                                                        Teacher Information #{index + 1}{' '}
                                                        {index === 0 ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                •{' '}
                                                                <span
                                                                    className='clickable'
                                                                    onClick={() => fields.pop()}
                                                                    style={{color: 'red'}}
                                                                >
                                                                    remove
                                                                </span>
                                                            </>
                                                        )}
                                                    </h5>
                                                    <FormGroup>
                                                        <Field
                                                            name={`${name}`}
                                                            options={teachers.map(t => ({
                                                                value: t.teacher_id,
                                                                label:
                                                                    t.teacher_id === 0
                                                                        ? 'Add new teacher below...'
                                                                        : t.first_name +
                                                                          ' ' +
                                                                          t.last_name,
                                                                teacher_id: t.teacher_id,
                                                                first_name: t.first_name,
                                                                last_name: t.last_name,
                                                                email: t.email,
                                                            }))}
                                                            render={({input, data, ...rest}) => (
                                                                <Select
                                                                    {...input}
                                                                    {...rest}
                                                                    // this is a necessary trick to make react-select show the placeholder.
                                                                    // if push an empty object to the teachers list, [{}], it will iterate an empty teacher,
                                                                    // but it thinks that it's an unnamed teacher due to the lack of  instead of
                                                                    value={
                                                                        data === {} ? null : data
                                                                    }
                                                                    placeholder='Select a teacher...'
                                                                />
                                                            )}
                                                            allowNull={true}
                                                        />
                                                        <Error
                                                            className='form-error'
                                                            name={`${index}_first_name`}
                                                        />
                                                    </FormGroup>
                                                    <div className='form-row'>
                                                        <FormGroup className='col-sm-6'>
                                                            <Field
                                                                name={`${name}.first_name`}
                                                                render={({input, ...rest}) => {
                                                                    console.log(
                                                                        'first name-disabled?',
                                                                        nameDisabled
                                                                    );
                                                                    return (
                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    First Name
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input
                                                                                {...input}
                                                                                {...rest}
                                                                                disabled={
                                                                                    nameDisabled
                                                                                }
                                                                            />
                                                                        </InputGroup>
                                                                    );
                                                                }}
                                                            />
                                                            <Error
                                                                className='form-error'
                                                                name={`${index}_first_name`}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className='col-sm-6'>
                                                            <Field
                                                                name={`${name}.last_name`}
                                                                render={({input, ...rest}) => (
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType='prepend'>
                                                                            <InputGroupText>
                                                                                Last Name
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            {...input}
                                                                            {...rest}
                                                                            disabled={nameDisabled}
                                                                        />
                                                                    </InputGroup>
                                                                )}
                                                                validate={required}
                                                            />
                                                            <Error
                                                                className='form-error'
                                                                name={`${index}_last_name`}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <FormGroup>
                                                        <Field
                                                            name={`${name}.email`}
                                                            render={({input, ...rest}) => (
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType='prepend'>
                                                                        <InputGroupText>
                                                                            Teacher Email
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input {...input} {...rest} />
                                                                </InputGroup>
                                                            )}
                                                            validate={required}
                                                        />
                                                        <Error
                                                            className='form-error'
                                                            name={`${index}_email`}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            name={`${name}.exam`}
                                                            options={exams
                                                                .map((e, index) => ({
                                                                    value: e.value,
                                                                    label: e.label,
                                                                    exam_id: e.value,
                                                                    exam_name: e.label,
                                                                }))
                                                                .sort((a, b) =>
                                                                    a.label.localeCompare(b.label)
                                                                )}
                                                            render={({input, ...rest}) => (
                                                                <Select
                                                                    {...input}
                                                                    {...rest}
                                                                    placeholder='Exam being administered...'
                                                                />
                                                            )}
                                                            validate={value =>
                                                                value ? undefined : 'required'
                                                            }
                                                        />
                                                        <Error
                                                            className='form-error'
                                                            name={`${index}_exam`}
                                                        />
                                                        <div className='text-right text-danger'>
                                                            <small>
                                                                (If the teacher is administering
                                                                more than one exam, select 'Add
                                                                Teacher' and re-enter the teacher's
                                                                information for each additional
                                                                exam.)
                                                            </small>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            name={`${name}.exam_count`}
                                                            render={({input, ...rest}) => (
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType='prepend'>
                                                                        <InputGroupText>
                                                                            How many exams will you
                                                                            be administering?
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        type='number'
                                                                        {...input}
                                                                        {...rest}
                                                                    />
                                                                </InputGroup>
                                                            )}
                                                            validate={value =>
                                                                value ? undefined : 'required'
                                                            }
                                                        />
                                                        <Error
                                                            className='form-error'
                                                            name={`${index}_exam_count`}
                                                        />
                                                        <div className='text-right text-danger'>
                                                            <small>
                                                                (Applied Practice will add an
                                                                additional 3 exams at no additional
                                                                cost to your order.)
                                                            </small>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            );
                                        })}
                                        <div
                                            className='col-sm-12'
                                            style={{textAlign: 'center'}}
                                            key={-1}
                                        >
                                            <Button
                                                color='primary'
                                                onClick={() => fields.push('teacher', undefined)}
                                            >
                                                Add another teacher
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </FieldArray>
                        </Wizard.Page>
                        <Wizard.Page>
                            <div>
                                <h3>
                                    Please select an administration method below, and review your
                                    order details before clicking 'Submit'.
                                </h3>
                                <div>
                                    <FormGroup>
                                        <Field
                                            name='submission_type'
                                            options={submission_types.map((e, index) => ({
                                                value: e.value,
                                                label: e.label,
                                            }))}
                                            render={({input, ...rest}) => (
                                                <Select
                                                    {...input}
                                                    {...rest}
                                                    placeholder='Administration Method'
                                                />
                                            )}
                                            validate={value => (value ? undefined : 'required')}
                                        />
                                        {/* >>>>>>>>Removed per request from Karen - 1/10/23<<<<<<<<<<<
                                        <Error className='form-error' name='submission_type' />
                                        <div className='text-right text-danger'>
                                           
                                            <small>
                                                (Currently, digital administration is only available
                                                for AP English Exams. Please email
                                                info@appliedpractice.com for more information.)
                                                </small> 
                                            
                                        </div>     */}
                                    </FormGroup>
                                </div>
                                {/* this is essentially a dummy page... I couldn't figure out how to pass
                            all the values to an individual Wizard.page. So the Review table is rendered by the <Wizard> component
                            instead of the <wizard.page> component*/}
                            </div>
                        </Wizard.Page>
                    </Wizard>
                )}
                {successfullySubmitted && (
                    <Alert color='success'>
                        Your registration has been successfully submitted!
                    </Alert>
                )}
            </Col>
        </Row>
    );
};

export default Register;
