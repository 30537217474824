import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import './ManualResponse.css';

const ManualResponse = () => {
  const dispatch = useDispatch();
  const auth = useAuth(dispatch);

  const [studentCode, setStudentCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  if (auth) {
    if (auth.isAdmin) return <Redirect to='/admin' />;
    else if (auth.isTeacher) return <Redirect to='/teacher' />;
    else if (auth.isReader) return <Redirect to='/reader' />;
    else if (auth.isStudent) return <Redirect to='/student' />;
  }

  const onChange = (event) => {
    const { value } = event.target;
    setStudentCode(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (studentCode) {
      auth.login({ studentCode });
    } else {
      setErrorMessage('Please enter a valid student passcode');
    }
  };

  return (
    <div className="template-container">
      <div className="header">
        <img src={`${process.env.PUBLIC_URL}/icons/logo.png`} alt="Logo" className="logo" />
        <h1 className="header-title">Applied Practice</h1>
      </div>
      <div className="content">
        <h5>Student Portal − Manual Response Upload</h5>
        <label htmlFor="studentPass" className="form-label">Enter Student Passcode</label>
        <input
          type="text"
          value={studentCode}
          onChange={onChange}
          id="studentPass"
          className="form-control border border-warning"
          required
        />
        <button
          type="button"
          onClick={onSubmit}
          className="btn btn-primary btn-sm mt-2 remove-question"
        >
          Sign In
        </button>

        {errorMessage && (
          <div className="alert alert-danger mt-3">
            {errorMessage}
          </div>
        )}
        <div className="message-box mt-4">
          <p>
            <b>ENTER YOUR STUDENT PASSCODE to UPLOAD completed exam files for scoring.</b>
          </p>
          <p className="note">
            NOTE: DO NOT ENTER your student passcode if you are taking your exam ONLINE. To take your exam online, RETURN TO THE MAIN PAGE and select Student Portal – Digital Exam.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ManualResponse;






