import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {Container} from 'reactstrap';
import './App.scss';
import Exam from './admin/exam/Exam';
import Home from './components/Home';
import Toasts from './components/Toast';
import NavbarTop from './components/navbar-footer/Navbar';
import Login from './components/Login';
import Logout from './components/Logout';
import Footer from './components/navbar-footer/Footer';
import OrderStatus from './admin/order_status/OrderStatus';
import SuperAdminHome from './admin/SuperAdminHome';
import ResponseMaintenance from './admin/answer/ResponseMaintenance';
import AssignUnassigned from './response/AssignUnassigned';
import GenerateAnswerSheets from './admin/answer_sheets/GenerateAnswerSheets';
import ImportScannedResponses from './admin/maintenance/ImportScannedResponses';
import ViewScannedResponseStatus from './admin/maintenance/ViewScannedResponseStatus';
import AssignRecategorize from './response/AssignRecategorize';
import ReviewBlanks from './admin/answer/ReviewBlanks';
import Registrant from './admin/registrant/Registrant';
import ReaderHome from './reader/ReaderHome';
import ReaderQuestion from './reader/ReaderQuestion';
import Register from './user/Register';
import ReportPage from './admin/reports/ReportPage';
import PreRegister from './user/PreRegister';
import Student from './student/Student';
import PasswordReset from './user/PasswordReset';
import ChangePassword from './user/ChangePassword';
import SetPassword from './user/SetPassword';
import TeacherHome from './teacher/TeacherHome';
import TeachersAdmin from './admin/teachers/TeachersAdmin';
import TestResponse from './admin/test_responses/TestResponse';
import TestResponsesList from './admin/test_responses/TestResponsesList';
import NotFound from './components/404NotFound';
import ManualResponse from './components/ManualResponse';

const nGauge = () => (
    <>
        <NavbarTop />
        <Toasts />
        <Container className='main-container'>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/login' component={Login} />
                <Route path = '/ManualResponse' component={ManualResponse}/>
                <Route path='/logout' component={Logout} />
                <Route path='/password-reset' component={PasswordReset} />
                <Route path='/change-password' component={ChangePassword} />
                <Route path='/set-password' component={SetPassword} />
                <Route path='/register' component={PreRegister} exact />
                <Route path='/register/enter' component={Register} exact />
                <Route path='/answer/import_step_1' component={ImportScannedResponses} />
                <Route path='/answer/import_step_2/:jobId' component={ViewScannedResponseStatus} />
                <Route path='/student' component={Student} />
                <Route path='/teacher' component={TeacherHome} />
                <Route path='/reader/question/:questionId' component={ReaderQuestion} />
                <Route exact path='/reader' component={ReaderHome} />
                <Route path='/admin' exact component={SuperAdminHome} />
                <Route path='/admin/report/:orderId' component={ReportPage} />
                <Route path='/admin/response-maintenance' component={ResponseMaintenance} />
                <Route path='/admin/assign-unreadable-responses' component={AssignUnassigned} />
                <Route path='/admin/recategorize-response' component={AssignRecategorize} />
                <Route path='/admin/review-blanks' component={ReviewBlanks} />
                <Route path='/admin/exams' component={Exam} />
                <Route path='/admin/registrants' component={Registrant} />
                <Route path='/admin/generate-answer-sheets' component={GenerateAnswerSheets} />
                <Route path='/admin/test-response/:responseId' component={TestResponse} />
                <Route path='/admin/test-responses' component={TestResponsesList} />
                <Route path='/admin/teachers' component={TeachersAdmin} />
                <Route path='/admin/order_status' component={OrderStatus} />
                <Route path='*' component={NotFound} />
            </Switch>
        </Container>
        <Footer />
    </>
);

export default nGauge;
