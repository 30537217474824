import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Passage = ({ passage, index, updatePassage, removePassage }) => {
    const updateQuestion = (qIndex, newQuestion) => {
        updatePassage(index, {
            ...passage,
            questions: passage.questions.map((q, idx) => (idx === qIndex ? newQuestion : q)),
        });
    };

    const addQuestion = (type) => {
        const newQuestion =
            type === 'multiple-choice'
                ? { type, question: '', options: [''] }
                : { type, question: '', answer: '' };
        updatePassage(index, {
            ...passage,
            questions: [...passage.questions, newQuestion],
        });
    };

    const removeQuestion = (qIndex) => {
        updatePassage(index, {
            ...passage,
            questions: passage.questions.filter((_, idx) => idx !== qIndex),
        });
    };

    const handlePassageChange = (content) => {
        updatePassage(index, { ...passage, passage: content });
    };

    return (
        <div className="mb-4 border p-3">
            <h3>Passage {index + 1}</h3>
            <button className="btn btn-danger btn-sm mb-3" onClick={() => removePassage(index)}>
                Remove Passage
            </button>
            <div className="mb-3">
                <label className="form-label">Passage Text:</label>
                <ReactQuill
                    value={passage.passage}
                    onChange={handlePassageChange}
                    theme="snow"
                    modules={{
                        toolbar: [
                            [{ 'font': [] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'script': 'sub' }, { 'script': 'super' }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'align': [] }],
                            ['link', 'image', 'video'],
                            ['clean']  // Remove formatting button
                        ]
                    }}
                    formats={[
                        'font', 'header', 'bold', 'italic', 'underline', 'strike',
                        'color', 'background', 'script', 'list', 'bullet', 'indent',
                        'direction', 'align', 'link', 'image', 'video'
                    ]}
                />
            </div>
            {passage.questions.map((question, qIndex) => (
                <Question
                    key={`question-${qIndex}`}
                    question={question}
                    pIndex={index}
                    qIndex={qIndex}
                    updateQuestion={updateQuestion}
                    removeQuestion={removeQuestion}
                />
            ))}
            <div>
                <button className="btn btn-primary btn-sm me-2" onClick={() => addQuestion('multiple-choice')}>
                    Add Multiple Choice Question
                </button>
                <button className="btn btn-primary btn-sm" style={{ margin: '8px' }} onClick={() => addQuestion('free-response')}>
                    Add Free Response Question
                </button>
            </div>
        </div>
    );
};

const Question = ({ question, pIndex, qIndex, updateQuestion, removeQuestion }) => {
    const handleQuestionChange = (content) => {
        updateQuestion(qIndex, { ...question, question: content });
    };

    const handleOptionChange = (optionIndex, newValue) => {
        const newOptions = question.options.map((option, idx) => (idx === optionIndex ? newValue : option));
        updateQuestion(qIndex, { ...question, options: newOptions });
    };

    const addOption = () => {
        updateQuestion(qIndex, { ...question, options: [...question.options, ''] });
    };

    const removeOption = (optionIndex) => {
        const newOptions = question.options.filter((_, idx) => idx !== optionIndex);
        updateQuestion(qIndex, { ...question, options: newOptions });
    };

    return (
        <div className="mb-2">
            <label className="form-label">Question {qIndex + 1}:</label>
            <ReactQuill
                value={question.question}
                onChange={handleQuestionChange}
                theme="snow"
                modules={{
                    toolbar: [
                        [{ 'font': [] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'align': [] }],
                        ['link', 'image', 'video'],
                        ['clean']  // Remove formatting button
                    ]
                }}
                formats={[
                    'font', 'header', 'bold', 'italic', 'underline', 'strike',
                    'color', 'background', 'script', 'list', 'bullet', 'indent',
                    'direction', 'align', 'link', 'image', 'video'
                ]}
            />
            {question.type === 'multiple-choice' && (
                <div className="mt-2">
                    {question.options.map((option, idx) => (
                        <div key={idx} className="mb-2 d-flex align-items-center">
                            <label className="form-label me-2">{String.fromCharCode(65 + idx)}:</label>
                            <ReactQuill
                                value={option}
                                onChange={(content) => handleOptionChange(idx, content)}
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        [{ 'font': [] }],
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ 'color': [] }, { 'background': [] }],
                                        [{ 'script': 'sub' }, { 'script': 'super' }],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                                        [{ 'direction': 'rtl' }],
                                        [{ 'align': [] }],
                                        ['link', 'image', 'video'],
                                        ['clean']  // Remove formatting button
                                    ]
                                }}
                                formats={[
                                    'font', 'header', 'bold', 'italic', 'underline', 'strike',
                                    'color', 'background', 'script', 'list', 'bullet', 'indent',
                                    'direction', 'align', 'link', 'image', 'video'
                                ]}
                            />
                            <button
                                className="btn btn-danger btn-sm"
                                style={{ height: '30px', width:'170px', margin: '3px'}}
                                onClick={() => removeOption(idx)}
                            >
                                Remove Option
                            </button>
                        </div>
                    ))}
                    <button className="btn btn-secondary btn-sm" onClick={addOption}>
                        Add Option
                    </button>
                </div>
            )}
            <button className="btn btn-danger btn-sm mt-2" onClick={() => removeQuestion(qIndex)}>
                Remove Question
            </button>
        </div>
    );
};

const TestBuilder = ({ passages, setPassages }) => {
    useEffect(() => {
    }, [passages]);

    const addPassage = () => {
        setPassages([...passages, { passage: '', questions: [] }]);
    };

    const removePassage = (index) => {
        const newPassages = passages.filter((_, i) => i !== index);
        setPassages(newPassages);
    };

    const updatePassage = (index, newPassage) => {
        const newPassages = passages.map((p, i) => (i === index ? newPassage : p));
        setPassages(newPassages);
    };

    return (
        <div>
            {passages.map((passage, index) => (
                <Passage
                    key={index}
                    passage={passage}
                    index={index}
                    updatePassage={updatePassage}
                    removePassage={removePassage}
                />
            ))}
            <button className="btn btn-secondary" onClick={addPassage}>
                Add Passage
            </button>
        </div>
    );
};

export default TestBuilder;
