import React, {useState, useEffect} from 'react';
import {useInterval} from '../student/utils';
import {Link} from 'react-router-dom';
import {Alert, Container, Row, Col, FormGroup, Label, Input, Button, Modal} from 'reactstrap';
import './GradingPage.css';
import ResponseImage from '../response/ResponseImage';
import ResponseText from '../response/ResponseText';
import Loading from '../components/waiting';
import _ from 'lodash';
import * as API from '../store/actions/fetchActions';
import {useDispatch, useSelector} from 'react-redux';

const Recategorize = props => {
    const {questionNumber, questionDdl, handle} = props;
    const filterQuestionDdl =
        questionDdl.length > 1 ? questionDdl.filter(q => q.name !== questionNumber) : questionDdl;

    const [recat, setRecat] = React.useState('blank');
    const radioClick = event => setRecat(event.target.value);

    const [recatQ, setRecatQ] = React.useState(filterQuestionDdl[0].name);
    const handleQ = event => setRecatQ(event.target.value);
    const [recatQ1, setRecatQ1] = React.useState(questionNumber);
    const handleQ1 = event => setRecatQ1(event.target.value);
    const [recatQ2, setRecatQ2] = React.useState(filterQuestionDdl[0].name);
    const handleQ2 = event => setRecatQ2(event.target.value);

    return (
        <Container className='recategorize'>
            <Row>
                <Col xs='12'>
                    <Input
                        type='radio'
                        value='blank'
                        checked={recat === 'blank'}
                        onChange={radioClick}
                    />
                    <Label for='recategorize-to-blank'>Response is BLANK</Label>
                </Col>

                <Col xs='12'>
                    <Input
                        type='radio'
                        value='recat_question'
                        checked={recat === 'recat_question'}
                        onChange={radioClick}
                    />
                    <Label for='recategorize-to-question-number'>
                        This is not Question {questionNumber}, it is Question
                        <select className='dropdown' value={recatQ} onChange={handleQ}>
                            {filterQuestionDdl.map(q => (
                                <option key={q.name} value={q.name}>
                                    {q.name}
                                </option>
                            ))}
                        </select>
                    </Label>
                </Col>

                <Col xs='12'>
                    <Input
                        type='radio'
                        value='recat_mixed'
                        checked={recat === 'recat_mixed'}
                        onChange={radioClick}
                    />
                    <Label for='recategorize-to-question-mixed'>
                        This is a mix of Question
                        <select className='dropdown' value={recatQ1} onChange={handleQ1} key={1}>
                            {questionDdl.map(q => (
                                <option key={q.name} value={q.name}>
                                    {q.name}
                                </option>
                            ))}
                        </select>
                        and Question
                        <select className='dropdown' value={recatQ2} onChange={handleQ2} key={2}>
                            {filterQuestionDdl.map(q => (
                                <option key={q.name} value={q.name}>
                                    {q.name}
                                </option>
                            ))}
                        </select>
                    </Label>
                </Col>

                <Col xs='12'>
                    <Input
                        type='radio'
                        value='other'
                        checked={recat === 'other'}
                        onChange={radioClick}
                    />
                    <Label for='recategorize-other'>UNKNOWN (other)</Label>
                </Col>

                {/*<Col xs="12">*/}
                {/*<Label for="recategorize-comments">Comments</label>*/}
                {/*<textarea id="recategorize-comments" name="recat_comments" class="form-control" rows="2"></textarea>*/}
                {/*</Col>*/}
            </Row>
            <Row>
                <Col>
                    <Button
                        color='danger'
                        onClick={() => {
                            handle({recat, recatQ, recatQ1, recatQ2});
                        }}
                    >
                        Recategorize
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const Rubric = props => {
    const {rubric, handle} = props;
    return (
        <Container className='scoringRubric'>
            <Row>
                <h6>SCORING RUBRIC</h6>
                {rubric.map((r, idx) => (
                    <Col xs='12' key={idx}>
                        <FormGroup tag='fieldset' name={r.part} onChange={handle}>
                            <strong>{r.part}</strong>
                            <br />
                            {[...Array(r.range + 1)].map((e, i) => (
                                <FormGroup check key={i}>
                                    {' '}
                                    &nbsp; &nbsp; &nbsp;
                                    <Label check>
                                        <Input type='radio' name={r.part} value={i} /> {i}
                                    </Label>
                                </FormGroup>
                            ))}
                        </FormGroup>
                        <br />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const Comments = props => {
    const {comments, handle} = props;
    const [checkmarks, setCheckmarks] = React.useState({});
    const handleCheck = event => {
        const {name, value, checked} = event.target;
        let newvalue = checkmarks[name];
        if (checked && !newvalue) {
            newvalue = [value];
        } else if (checked) {
            newvalue.push(value);
        } else if (newvalue) {
            newvalue = newvalue.filter(v => v !== value);
        }
        const newchecks = {
            ...checkmarks,
            [name]: newvalue,
        };
        setCheckmarks(newchecks);
        handle({
            target: {
                name: name,
                value: newvalue.join('\n'),
            },
        });
    };
    return (
        <Col xs='12'>
            <div className='scoringComments container'>
                <h6>SCORING COMMENTS</h6>
                {comments.map((c, idx) => (
                    <Col xs='12' key={idx}>
                        <FormGroup check name={c.part}>
                            <legend>
                                <small>{c.part}</small>
                            </legend>
                            <ul>
                                {c.comments.map((cmt, idx) => (
                                    <li key={idx}>
                                        <Label check key={idx}>
                                            <Input
                                                type='checkbox'
                                                name={c.part}
                                                value={cmt.text}
                                                onChange={handleCheck}
                                            />
                                            {cmt.text}
                                        </Label>
                                    </li>
                                ))}
                            </ul>
                        </FormGroup>
                    </Col>
                ))}
                <FormGroup>
                    <Label for='txtComments'>Other Comments:</Label>
                    <Input
                        type='textarea'
                        name='txtComments'
                        id='txtComments'
                        rows={5}
                        onChange={handle}
                    />
                </FormGroup>
            </div>
        </Col>
    );
};

const TimeoutModal = props => {
    const {isOpen, loading, handle} = props;
    return (
        <Modal isOpen={isOpen}>
            <Container>
                <h3>Do you need more time?</h3>
                {loading ? (
                    <Loading />
                ) : (
                    <Button color='primary' block onClick={handle}>
                        Reset Timeout
                    </Button>
                )}
            </Container>
        </Modal>
    );
};

const Page = props => {
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [activityTimer, setActivityTimer] = useState(null);
    const [cancelTimer, setCancelTimer] = useState(null);
    const [scores, setScores] = useState({});
    const [mapScores, setMapScores] = useState([]);
    const [filled, setFilled] = useState(false);
    const [comments, setComments] = useState({txtComments: null});
    const [mapComments, setMapComments] = useState([]);
    const dispatch = useDispatch();
    const refreshing = useSelector(state => state.reader.refreshingCheckout);

    const {questionId, responseId} = props;

    useEffect(() => {
        setActivityTimer(refreshing ? null : 1500000);
    }, [refreshing]);

    useInterval(
        () => {
            setActivityTimer(null);
            setCancelTimer(300000);
            setShowTimeoutModal(true);
        },
        activityTimer,
        true
    );

    const doCancel = () => {
        setActivityTimer(null);
        setCancelTimer(null);
        setShowTimeoutModal(false);
        props.onCancel(questionId, responseId);
    };

    useInterval(doCancel, cancelTimer, true);

    const checkFilled = (scoreValues, commentValues) => {
        const si = _.range(props.rubric.length);
        const scoresFilled = si.reduce((acc, i) => acc && scoreValues[i] !== undefined, true);
        if (props.presetCommentsRequired) {
            const ci = props.comments.reduce(
                (acc, part) => (part.comments.length > 0 ? [...acc, part.part] : acc),
                []
            );
            const commentsFilled = ci.reduce((acc, i) => acc && !!commentValues[i], true);
            setFilled(scoresFilled && commentsFilled);
        } else {
            setFilled(scoresFilled);
        }
    };

    const handleScore = event => {
        const newScores = {
            ...scores,
            [event.target.name]: event.target.value,
        };
        setScores(newScores);
        const newMap = props.rubric.map(r => newScores[r.part]);
        setMapScores(newMap);
        checkFilled(newMap, comments);
    };

    const handleComment = event => {
        const newComments = {
            ...comments,
            [event.target.name]: event.target.value,
        };
        setComments(newComments);
        setMapComments(props.rubric.map(r => newComments[r.part]));
        // what if event.target.name is not one of props.rubric.part ??
        checkFilled(mapScores, newComments);
    };

    const doExtend = () => {
        setCancelTimer(null);
        setShowTimeoutModal(false);
        dispatch(API.refreshReaderLock(questionId, responseId));
    };

    const doRecategorize = data => {
        setActivityTimer(null);
        setCancelTimer(null);
        dispatch(API.putRecategorize(questionId, responseId, data));
    };

    const doSubmit = event => {
        setActivityTimer(null);
        setCancelTimer(null);
        dispatch(
            API.postResponseScore(questionId, {
                keepGoing: event.target.value === 'next',
                responseId: responseId,
                scores: mapScores.map((e, i) => ({
                    part: props.questionNumber + '.' + props.rubric[i].part,
                    score: e,
                    comment: mapComments[i],
                })),
                otherComment: comments.txtComments,
            })
        );
    };

    return (
        <React.Fragment>
            <TimeoutModal isOpen={showTimeoutModal} handle={doExtend} loading={refreshing} />
            {props.testResponse && (
                <Row>
                    <Col xs='12'>
                        <Alert color='danger'>
                            This is a test response to help you calibrate your scoring.
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col sm='8'>
                    <h2>Response - {props.responseId}</h2>
                </Col>
                <Col sm='8'>
                    <h2>Response - {props.responseId}</h2>
                </Col>
                <Col sm='8'>
                    {props.children}
                    <ResponseText html={props.html} />
                    {props.images.map((img, idx) => (
                        <ResponseImage key={idx} url={img} />
                    ))}
                </Col>
                <Col sm='4'>
                    {!props.testResponse && (
                        <Recategorize
                            questionNumber={props.questionNumber}
                            questionDdl={props.questionDdl}
                            handle={doRecategorize}
                        />
                    )}
                    <Rubric rubric={props.rubric} handle={handleScore} />
                </Col>
                <Comments comments={props.comments} handle={handleComment} />
            </Row>
            {}
            <Row>
                {!props.testResponse && (
                    <Col>
                        <Button
                            color='primary'
                            block
                            value='finish'
                            onClick={doSubmit}
                            disabled={!filled}
                        >
                            Submit - I'm finished scoring
                        </Button>
                    </Col>
                )}
                <Col>
                    <Button color='primary' block value='cancel' onClick={doCancel}>
                        Cancel
                    </Button>
                </Col>
                {!props.testResponse && (
                    <Col>
                        <Button
                            color='primary'
                            block
                            value='next'
                            onClick={doSubmit}
                            disabled={!filled}
                        >
                            Submit - Score another essay
                        </Button>
                    </Col>
                )}
                {props.testResponse && (
                    <Col>
                        <Link to={'/reader'}>
                            <Button
                                color='primary'
                                block
                                value='next'
                                onClick={doSubmit}
                                disabled={!filled}
                            >
                                Submit - Back to reader home
                            </Button>
                        </Link>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};

export default Page;
